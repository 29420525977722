define("portal/helpers/convert-time-format", ["exports", "@outdoorsyco/ember-shared-data/helpers/convert-time-format"], function (_exports, _convertTimeFormat) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _convertTimeFormat.default;
    }
  });
  Object.defineProperty(_exports, "convertTimeFormat", {
    enumerable: true,
    get: function get() {
      return _convertTimeFormat.convertTimeFormat;
    }
  });
});